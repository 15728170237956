/* eslint-disable vue/no-dupe-keys */
<template>
    <div class="videosface" v-show="video.videosfacefalg">
        <div class="attestation att_flo">
            <!-- <div class="attestation att_flo" v-show="video.recognition"> -->
            <span class="posi_quxiao" @click="cancel">
                <i class="el-icon-close"></i>
            </span>
            <div class="real_titles">
                <span>人脸识别</span>
            </div>
            <div class="recognition_titles">
                <p>为确保本人操作，请先进行人脸识别</p>
                <p v-show="!camerafalg">抓拍时请注意衣着姿态及周身环境，保护自身隐私</p>
                <div class="camera">
                    <div v-show="!camerafalg && !notcamerafalg">
                        <video ref="refVideo" id="video" autoplay class="video"></video>
                        <canvas
                            width="320px"
                            height="320px"
                            ref="refCanvas"
                            :style="{ opacity: 0 }"
                            class="canvas"
                        ></canvas>
                    </div>
                    <div class="identify" v-show="camerafalg">
                        <img src="@/assets/image/shibie2.png" alt />
                    </div>
                    <div class="identify" v-show="notcamerafalg">
                        <img src="@/assets/image/shibie1.png" alt />
                    </div>
                </div>
                <div v-show="!camerafalg && !notcamerafalg">
                    <p class="attes_font" @click="playVideo">{{ codemags }}</p>
                    <p class="attes_font2">技能培训为何需要采集人脸信息</p>
                </div>
                <div v-show="camerafalg" class="cam_btn">
                    <p class="attes_font">识别成功</p>
                    <el-button type="primary" @click="next">
                        {{
                        nextCancel.next
                        }}
                    </el-button>
                </div>
                <div v-show="notcamerafalg" class="cam_btn">
                    <p class="attes_font">识别失败</p>
                    <div>
                        <el-button @click="cancels">{{ nextCancel.cancel }}</el-button>
                        <el-button type="primary" @click="playvideosface">重新识别</el-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="video.detection" class="attestation att_flo">
            <span class="posi_quxiao" @click="cancel">
                <i class="el-icon-close"></i>
            </span>
            <div class="real_titles">
                <span>提示</span>
            </div>
            <div class="recognition_titles">
                <div class="dete_icon">
                    <img src="@/assets/image/tishi-shex.png" alt />
                </div>
                <span class="dete_name">未检测到摄像头</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["video", "video_id", "movieid"],
    data() {
        return {
            nextCancel: {
                next: "下一步",
                cancel: "取消",
            },
            camerafalg: false,
            notcamerafalg: false,
            codemags: "开始识别",
            /* 人脸识别 */
            URL: null,
            streamIns: null, // 视频流
            showContainer: true, // 显示
            tracker: null,
            tipFlag: false, // 提示用户已经检测到
            flag: false, // 判断是否已经拍照
            context: null, // canvas上下文
            removePhotoID: null, // 停止转换图片
            imgUrl: "", // base64格式图片
            countdowns: 3,
            timers: null,
            faceTime:null,//
            // eslint-disable-next-line vue/no-dupe-keys
        };
    },
    created() {
        this.notcamerafalg = true;
    },
    methods: {
        playVideo() {
            this.notcamerafalg = false;
            this.playVideos();
            this.$axios.post(this.$api.jian_10,{
                video_id:this.currentVideo.video_id
            })
        },
        //开始识别
        playVideos() {
            this.getUserMedia(
                {
                    video: {
                        width: 320,
                        height: 320,
                        facingMode: "user",
                    } /* 前置优先 */,
                },
                this.success,
                this.error
            );
        },
        // // 人脸捕捉
        initTracker() {
            this.context = this.$refs.refCanvas.getContext("2d"); // 画布
            this.removePhotoID = setTimeout(() => {
                this.tackPhoto();
                this.tipFlag = true;
            }, 10000);
            
        },
        // // 访问用户媒体设备
        getUserMedia(constrains, success, error) {
            if (navigator.mediaDevices.getUserMedia) {
                //最新标准API
                navigator.mediaDevices
                    .getUserMedia(constrains)
                    .then(success)
                    .catch(error);
            } else if (navigator.webkitGetUserMedia) {
                //webkit内核浏览器
                navigator
                    .webkitGetUserMedia(constrains)
                    .then(success)
                    .catch(error);
            } else if (navigator.mozGetUserMedia) {
                //Firefox浏览器
                navigator
                    .mozGetUserMedia(constrains)
                    .then(success)
                    .catch(error);
            } else if (navigator.getUserMedia) {
                //旧版API
                navigator.getUserMedia(constrains).then(success).catch(error);
            } else {
                this.$message({
                    message: "你的浏览器不支持访问用户媒体设备",
                    type: "warning",
                });
            }
        },
        success(stream) {
            this.streamIns = stream;
            // webkit内核浏览器
            this.URL = window.URL || window.webkitURL;
            if ("srcObject" in this.$refs.refVideo) {
                this.$refs.refVideo.srcObject = stream;
            } else {
                this.$refs.refVideo.src = this.URL.createObjectURL(stream);
            }

            this.$refs.refVideo.onloadedmetadata = () => {
                this.$refs.refVideo.play();
                this.initTracker();
            };
        },
        error() {
            this.video.recognition = false;
            this.video.detection = true;
        },
        // // 拍照
        tackPhoto() {
            this.context.drawImage(
                this.$refs.refVideo,
                0,
                0,
                this.context.canvas.width,
                this.context.canvas.height
            );
            // 保存为base64格式
            this.imgUrl = this.saveAsPNG(this.$refs.refCanvas);
            this.flag = false;
            this.tipFlag = false;
            /** 拿到base64格式图片之后就可以在this.compare方法中去调用后端接口比较了，也可以调用getBlobBydataURI方法转化成文件再去比较
             * 我们项目里有一个设置个人头像的地方，先保存一下用户的图片，然后去拿这个图片的地址和当前拍照图片给后端接口去比较。
             * */
            this.compare(this.imgUrl);
        },
        // Base64转文件
        getBlobBydataURI(dataURI, type) {
            var binary = window.atob(dataURI.split(",")[1]);
            var array = [];
            for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {
                type: type,
            });
        },
        compare(url) {
            let blob = this.getBlobBydataURI(url, "image/png");

            this.uploadImg(blob);
            // baiduFaceCheck({ image: url.split(",")[1], image_type: "BASE64" }).then(
            //   res => {
            //     if (res.data.code === 1) {
            //       if (JSON.parse(res.data.data).error_msg === "SUCCESS") {
            //
            //       } else {
            //         this.notcamerafalg = true;
            //         this.close();
            //       }
            //     }
            //   }
            // );
        },
        //s上传图片
        uploadImg(blob) {
            let formData = new FormData();
            formData.append(
                "image",
                blob,
                "file_" + Date.parse(new Date()) + ".png"
            );
            formData.append("token", sessionStorage.getItem("token"));
            // console.log(formData);video_id
            formData.append("video_id", this.video_id);
            // this.getImgList(formData);
            this.$axios.post("/api/study/faceAuth", formData).then((res) => {
                if (res.code === 1) {
                    if (res.data.status == 1) {
                        this.camerafalg = !this.camerafalg;
                        this.close();
                        clearTimeout(this.removePhotoID);
                        this.removePhotoID = null;
                    } else {
                        clearTimeout(this.removePhotoID);
                        this.removePhotoID = null;
                        this.notcamerafalg = true;
                        this.close();
                        console.log('0');
                    }
                }
            });
            // upload_one_image(formData)
            //   .then(res => {
            //     if (res.data.code === 1) {
            //       this.face = res.data.data.file_id;
            //       let formData = new FormData();
            //       formData.append("token", getcache("token"));
            //       formData.append("video_id", this.video_id);
            //       formData.append("course_id", this.movieid);
            //       formData.append("face", res.data.data.file_id);
            //       this.getImgList(formData);
            //     }
            //   })
            //   .catch(err => {
            //     console.log(err);
            //   });
        },

        //人脸识别
        getImgList() {
            // faceAuth(formData)
            //   .then(res => {
            //     if (res.data.code === 1) {
            //       if (res.data.data.status == 1) {
            //         this.camerafalg = !this.camerafalg;
            //         this.close();
            //       } else {
            //         this.notcamerafalg = true;
            //         this.close();
            //       }
            //     }
            //   })
            //   .catch(err => {
            //     console.log(err);
            //   });
        },
        /* ============= */
        // // 保存为png,base64格式图片
        saveAsPNG(c) {
            return c.toDataURL("image/png", 0.3);
        },

        // // 关闭并清理资源
        close() {
            this.flag = false;
            this.tipFlag = false;
            this.showContainer = true;
            this.tracker &&
                this.tracker.removeListener("track", this.handleTracked);
            this.tracker = null;
            this.context = null;
            clearTimeout(this.removePhotoID);
            this.removePhotoID = null;
            if (this.streamIns) {
                this.streamIns.enabled = false;
                this.streamIns.getTracks()[0].stop();
                this.streamIns.getVideoTracks()[0].stop();
            }
            this.$forceUpdate();
            this.streamIns = null;
            // this.playVideo()
        },
        /* ------------ */
        next() {
            this.video.recognition = false;
            this.$emit("nextok");
            this.camerafalg = null;
            this.notcamerafalg = null;
        },
        playvideosface() {
            this.notcamerafalg = false;
            this.playVideo();
        },
        //取消按钮
        cancel() {
            this.video.recognition = false;
            this.video.detection = false;

            this.notcamerafalg = true;
            this.$emit("nextoks", this.video);
            this.close();
        },
        //取消按钮
        cancels() {
            this.video.recognition = false;
            this.video.detection = false;
            this.notcamerafalg = true;
            this.$emit("nextoks", this.video);
            this.close();
        },
    },
};
</script>

<style lang="scss" scped>
.posi_quxiao {
    position: absolute;
    right: 20px;
    font-size: 30px;
}
.videosface {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    z-index: 99;
}
.attestation {
    position: absolute;
    background: #ffffff;
    top: 24%;
    left: 24%;
    margin-top: -100px;
    margin-left: -100px;
    border-radius: 8px;
    overflow: hidden;
}
.real_titles {
    background: #f7faff;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        padding: 20px 0;
        display: inline-block;
        font-size: 24px;
        color: #1678ff;
    }
}
.recognition_titles {
    width: 1100px;
    text-align: center;
    .dete_icon {
        width: 600px;
        height: 500px;
        img {
            margin-left: 230px;
        }
    }
    .dete_name {
        display: inline-block;
        color: #ff6f18;
        font-size: 20px;
        margin-bottom: 30px;
    }
    p:nth-child(1) {
        padding: 30px 0 40px 0;
        font-size: 26px;
        text-align: center;
    }

    p:nth-child(2) {
        font-size: 20px;
        color: #ff6f18;
        padding-bottom: 40px;
    }

    .attes_font {
        text-align: center;
        font-size: 26px;
        padding-bottom: 23px;
        cursor: default;
    }

    .attes_font2 {
        text-align: center;
        font-size: 20px;
        color: #ff6f18;
        padding-bottom: 100px;
    }
}
.avatar {
    width: 348px;
    height: 212px;
    display: inline-block;
}
.camera {
    display: flex;
    justify-content: center;
    align-items: center;

    .identify {
        width: 320px;
        height: 320px;
        border-radius: 290px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
    }

    div {
        width: 320px;
        height: 320px;
        background: black;
        border-radius: 290px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;

        .canvas {
            width: 320px;
            height: 320px;
            border-radius: 290px;
            opacity: 0;
            z-index: -1;
        }

        .video {
            width: 320px;
            height: 320px;
            border-radius: 290px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
        }
    }
}

.cam_btn {
    margin-bottom: 40px;
    .el-button {
        width: 380px;
        padding: 16px 0;
        font-size: 18px;
    }
}
</style>
